import React from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import { config } from "../services/config";

// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");


const PushpaVideoPage = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard">
          <MaterialTable
            columns={[
              { title: "Title", field: "title" },
              { title: "Video ID", field: "videoId" },
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                fetch(config.pushpaVideos + `/${query.pageSize}?page=${query.page + 1 }`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                  }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success || result.success === false) {
                      resolve({
                        data: result.data.data,
                        page: query.page,
                        totalCount: result.data.total
                      });
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
            }
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  fetch(config.pushpaVideos , {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({
                      title: newData.title,
                      videoId: newData.videoId
                    })
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
              }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  fetch(config.deletePushpaVideos + oldData.id, {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                }),
                onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  fetch(config.editPushpaVideos + oldData.id, {
                    method: "PUT",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({
                      title: newData.title,
                      videoId: newData.videoId
                    })
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                })
            }}
            title="Pushpa's Videos"
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false
            }}
          />
      </div>
    </div>
  );
};

export default PushpaVideoPage;
