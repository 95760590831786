import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const addPress = async (data,thumbnail) => {
    let formData = new FormData();
    await formData.append('media_name', data.name)
    await formData.append('title', data.title)
    await formData.append('url', data.url)
    await formData.append('image',thumbnail[0])

    return axios.post(config.addPress, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}


export const editPressnMedia = async (data,id,image) => {    
    let formData = new FormData();
    await formData.append('media_name', data.media_name)
    await formData.append('title', data.title)
    await formData.append('url', data.url)    
    if(typeof(image)==="string"){
        await formData.append('image',image)
    }else if(typeof(image)==="object"){
        await formData.append('image',image[0])
    }

    return axios.post(config.editPressnMedia+id, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}