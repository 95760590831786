import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const getOrders = () => {
    return axios.get(config.orders)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log(error);
        })
}


export const postProducts = async (data, image, thumbnail) => {
    let formData = new FormData();
    let images = image[0]
    for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i])
    }
    await formData.append('product_name', data.title)
    await formData.append('product_desc', data.description)
    await formData.append('thumbnail', thumbnail[0])

    return axios.post(config.ourProducts, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const deleteProducts = async (id) => {
    return axios.delete(config.deleteProducts + id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const deleteProductImage = async (id) => {
    return axios.delete(config.deleteProductImage + id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const editProduct = async (data, id, image, thumbnail) => {
    let formData = new FormData();
    if (image.length > 0) {
        let images = image[0]
        for (let i = 0; i < images.length; i++) {
            formData.append('images[]', images[i])
        }
    }
    await formData.append('product_name', data.title)
    await formData.append('product_desc', data.description)

    if (typeof (thumbnail) === "string") {
        await formData.append('thumbnail', thumbnail)
    } else if (typeof (thumbnail) === "object") {
        await formData.append('thumbnail', thumbnail[0])
    }

    return axios.post(config.editProducts + id, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}