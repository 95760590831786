import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from "@material-ui/core";
import Draggable from "react-draggable";

import { changePassword } from "../../services/changePassword/ChangePassword";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const EditHistoryTimeline = ({ openStatus, onCloseClick }) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm();
  const onSubmit = async data => {
    console.log("data", data);
    setLoading(true);
    let changepasswordResponse = await changePassword(data)
    setLoading(false);
    if (changepasswordResponse.data.success === true) {
        window.document.location.reload()
        onCloseClick()
    }
  };
  return (
    <Dialog
      open={openStatus}
      onClose={onCloseClick}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Change Password
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div
            style={{
              width: 500,
              display: "grid",
              gridTemplateColumns: "1fr",
              gridRowGap: 10
            }}
          >
            <TextField
              type="password"
              name="old_password"
              inputRef={register({ required: true })}
              label="Old Password"
              variant="outlined"
              required
            />

            <TextField
              type="password"
              name="new_password"
              inputRef={register({ required: true })}
              label="New Password"
              variant="outlined"
              required
            />

            <TextField
              type="password"
              name="confirm_password"
              inputRef={register({ required: true })}
              label="Confirm Password"
              variant="outlined"
              required
            />
          </div>
        </DialogContent>

        {loading ? (
          <div
            style={{
              width: "100%",
              marginBottom: "20px",
              display: "grid",
              justifyItems: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <DialogActions>
            <Button
              autoFocus
              onClick={onCloseClick}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

export default EditHistoryTimeline;
