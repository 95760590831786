import React, { useState } from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import { config } from "../../services/config";

//COMPONENT
import EditArticle from "../editArticle/EditArticle.component"

// SERVICES 
import {deleteArticleImage} from "../../services/ourWork/ourWork";

// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");

const ArticleList = (props) => {
  const [dataEdit, setDataEdit] = useState();  
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ position: "relative", zIndex: 0, marginBottom: 20 }}>
      <a href="#add-article">
        <div className="table-addbutton text-rumpel">
          ADD
            </div>
      </a>
      <MaterialTable
        columns={[
          {
            title: "Thumbnail",
            field: "thumbnail",
            render: rowData => (
              <img
                alt="ArticleImages"
                style={{ width: 250, borderRadius: 4 }}
                src={rowData.thumbnail}
              />
            )
          },
          { title: "Title", field: "title" },
          { title: "Programee Name", field: "name", defaultGroupOrder: 0  }
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            fetch(config.listArticles + `/${query.pageSize}?page=${query.page + 1}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.success) {
                  resolve({
                    data: result.data.data,
                    page: query.page,
                    totalCount: result.data.total
                  });
                } else {
                  reject(console.log("error"));
                }
              });
          })
        }
        detailPanel={rowData => {
          const deleteHandler = async (id) =>{
            let deleteImageResponse = await deleteArticleImage(id);
            if(deleteImageResponse.data.success===true){
              window.document.location.reload()
          }
            
          }
          return (
            <div
              style={{
                padding: 20,
                display: "grid",
                gridTemplateColumns: "repeat(9, 1fr)"
              }}
            >
              {rowData.images &&
                rowData.images.map((item, index) => {
                  return (
                    <div
                      style={{
                        position: "relative",
                        backgroundImage: `url(${item.images})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        gridTemplateColumns: "1fr",
                        height: 100,
                        width: 100
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 10,
                          color: "red",
                          cursor: "pointer",
                          fontSize: 20
                        }}
                        className="font-averta text-bold"
                        onClick={() => deleteHandler(item.id)}
                      >
                        x
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        }}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              fetch(config.deleteArticles + oldData.id, {
                method: "DELETE",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
                }
              })
                .then(response => response.json())
                .then(result => {
                  if (result.success) {
                    resolve();
                  } else {
                    reject(console.log("error"));
                  }
                });
            }),
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setDataEdit(rowData)
              handleClickOpen()
              document.getElementById('add-article').scrollIntoView()

            }
          }
        ]}
        title="Add Articles"
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          search: false,
          grouping: true
        }}
        
      />

        <EditArticle editValue={dataEdit} openStatus={open} onCloseClick={handleClose} programlist={props.programlist}/>

    </div>
  );
};

export default ArticleList;
