import React from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import { config } from "../services/config";



// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");



const MapPage = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard" style={{ position:"relative",zIndex: 0 }}>
        <MaterialTable
          columns={[{ title: "District", field: "district" }]}
          data={query =>
            new Promise((resolve, reject) => {
              fetch(config.district, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
                }
              })
                .then(response => response.json())
                .then(result => {
                  if (result.success) {
                    resolve({
                      data: result.data
                    });
                  } else {
                    reject(console.log("error"));
                  }
                });
            })
          }
          detailPanel={rowData => {
            return (
              <div style={{ padding: 40 }}>
                <MaterialTable
                  title={rowData.district}
                  columns={[
                    {
                      title: "Programme",
                      field: "programme_name",
                      editable: "never"
                    },
                    {
                      title: "Male",
                      field: "male",
                      editComponent: ({ onRowDataChange, rowData }) => {
                        const onChangeText = e => {
                          onRowDataChange({
                            ...rowData,
                            male: e.target.value
                          });
                        };
                        if (
                          rowData.programme_id === 0 ||
                          rowData.programme_id === 1
                        ) {
                          return (
                            <input
                              type="number"
                              className="map-input"
                              defaultValue={rowData.male}
                              placeholder="edit here"
                              onChange={e => onChangeText(e)}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    },
                    {
                      title: "Female",
                      field: "female",
                      editComponent: ({ onRowDataChange, rowData }) => {
                        const onChangeText = e => {
                          onRowDataChange({
                            ...rowData,
                            female: e.target.value
                          });
                        };
                        if (
                          rowData.programme_id === 0 ||
                          rowData.programme_id === 1
                        ) {
                          return (
                            <input
                              type="number"
                              className="map-input"
                              defaultValue={rowData.female}
                              placeholder="edit here"
                              onChange={e => onChangeText(e)}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    },
                    {
                      title: "Date",
                      field: "distribution_date",
                      editComponent: ({ onRowDataChange, rowData }) => {
                        const onChangeText = e => {
                          onRowDataChange({
                            ...rowData,
                            distribution_date: e.target.value
                          });
                        };
                        if (rowData.programme_id === 0) {
                          return (
                            <input
                              type="date"
                              className="map-input"
                              defaultValue={rowData.distribution_date}
                              placeholder="edit here"
                              onChange={e => onChangeText(e)}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    },
                    {
                      title: "Total Meal",
                      field: "total_meal_served",
                      editComponent: ({ onRowDataChange, rowData }) => {
                        const onChangeText = e => {
                          onRowDataChange({
                            ...rowData,
                            total_meal_served: e.target.value
                          });
                        };
                        if (rowData.programme_id === 3) {
                          return (
                            <input
                              type="number"
                              className="map-input"
                              defaultValue={rowData.total_meal_served}
                              placeholder="edit here"
                              onChange={e => onChangeText(e)}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    },
                    {
                      title: "Beneficiary",
                      field: "beneficiary_num",
                      editComponent: ({ onRowDataChange, rowData }) => {
                        const onChangeText = e => {
                          onRowDataChange({
                            ...rowData,
                            beneficiary_num: e.target.value
                          });
                        };
                        if (rowData.programme_id === 2) {
                          return (
                            <input
                              type="number"
                              className="map-input"
                              defaultValue={rowData.beneficiary_num}
                              placeholder="edit here"
                              onChange={e => onChangeText(e)}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    }
                  ]}
                  data={query =>
                    new Promise((resolve, reject) => {
                      fetch(config.mapData + `${rowData.district}`, {
                        method: "GET",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json"
                        }
                      })
                        .then(response => response.json())
                        .then(result => {
                          if (result.success) {
                            console.log("data", result);
                            resolve({
                              data: result.data
                            });
                          } else {
                            reject(console.log("error"));
                          }
                        });
                    })
                  }
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      oldData.id
                        ? new Promise((resolve, reject) => {
                            console.log("newdata", newData);
                            fetch(config.editMapPut + oldData.id, {
                              method: "PUT",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`
                              },
                              body: JSON.stringify({
                                male: newData.male ? newData.male : null,
                                female: newData.female ? newData.female : null,
                                distributionDate: newData.distribution_date
                                  ? newData.distribution_date
                                  : null,
                                beneficiaryNum: newData.beneficiary_num
                                  ? newData.beneficiary_num
                                  : null,
                                mealServed: newData.total_meal_served
                                  ? newData.total_meal_served
                                  : null
                              })
                            })
                              .then(response => response.json())
                              .then(result => {
                                if (result.success) {
                                  console.log("Result", result);
                                  resolve();
                                } else {
                                  reject(console.log("error"));
                                }
                              });
                          })
                        : new Promise((resolve, reject) => {
                            console.log("newdata", oldData);
                            fetch(config.addMapPost, {
                              method: "POST",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`
                              },
                              body: JSON.stringify({
                                districtId: oldData.district_id,
                                programmeId: oldData.programme_id,
                                programmeName: oldData.programme_name,
                                male: newData.male ? newData.male : null,
                                female: newData.female ? newData.female : null,
                                distributionDate: newData.distribution_date
                                  ? newData.distribution_date
                                  : null,
                                beneficiaryNum: newData.beneficiary_num
                                  ? newData.beneficiary_num
                                  : null,
                                mealServed: newData.total_meal_served
                                  ? newData.total_meal_served
                                  : null
                              })
                            })
                              .then(response => response.json())
                              .then(result => {
                                if (result.success) {
                                  console.log("Result", result);
                                  resolve();
                                } else {
                                  reject(console.log("error"));
                                }
                              });
                          })
                  }}
                  options={{
                    search: false,
                    actionsColumnIndex: -1,
                    paging: false
                  }}
                />
              </div>
            );
          }}
          title="Map Data"
          options={{
            emptyRowsWhenPaging: false,
            search: false,
            paging: false
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </div>
    </div>
  );
};

export default MapPage;
