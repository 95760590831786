import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { PUBLIC_PATHS } from "../../app/Paths.app";
import { PublicLink, isLoggedIn } from "../../utils/Utils";
import Cookies from "universal-cookie";

// IMPORT IMAGES
import ECDCLogo from "../../../assets/icons/Ecdclogo_nobg.svg";
import UsernameLogo from "../../../assets/icons/Username.png";
import DropdownLogo from "../../../assets/icons/Dropdown.png";

// IMPORT COMMON
import ChangePassword from "../changePassword/ChangePassword.common";

// COOKIE
const cookie = new Cookies();

const Header = () => {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [open, setOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    }

    // LOGOUT
    const handleLogout = () => {
        // REMOVE THE COOKIE
        cookie.remove("browser_ID");

        window.document.location.reload();
    }

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div className="header-container">
            <div className="header">
                <div className="header-top">
                    <div className="header-top-left">
                        <img src={ECDCLogo} alt="ECDC LOGO" />

                        <span className="header-title text-rumpel">
                            ECDC DASHBOARD
                        </span>
                    </div>

                    <div className="header-top-right">
                        {
                            !isLoggedIn() ? 
                            <ul className="header-nav">
                                {
                                    PUBLIC_PATHS.map((props, index) => (
                                        <PublicLink 
                                            key={index}
                                            {...props}
                                        >
                                            <li className="header-nav-item">
                                                <NavLink to={props.path} activeClassName="menu-active">LOGIN</NavLink>
                                            </li>
                                        </PublicLink>
                                    ))
                                }
                            </ul> 
                            : 
                            <div className="header-top-right-logged" onClick={() => toggleDropdown()}>
                                <img className="user" src={UsernameLogo} alt="USER" />

                                <span className="header-top-right-logged-role">
                                    ADMIN
                                </span>

                                <img className="dropdown" src={DropdownLogo} alt="DROPDOWN" />
                                
                                {
                                    dropdownVisible &&
                                    <ul className="header-top-right-logged-dropdown">
                                        <li onClick={() => handleClickOpen()} className="logged-dropdown-item text-red">CHANGE PASSWORD</li>
                                        <li onClick={() => handleLogout()} className="logged-dropdown-item text-red">LOGOUT</li>
                                    </ul>
                                }
                                
                            </div>
                        }
                        
                    </div>
                </div>
                <ChangePassword openStatus={open} onCloseClick={handleClose} />
            </div>
        </div>
    )
}

export default Header;