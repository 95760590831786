import React, {useState} from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import MaterialTable from "material-table";
import { useForm, Controller } from 'react-hook-form'
import { TextField, Slide, Button, CircularProgress } from '@material-ui/core';
import { createProgramme } from "../../services/ourWork/ourWork"
import Cookies from "universal-cookie";

import { config } from "../../services/config";


// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");

const ProgramList = ({ programlist, projectlist }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = React.useState(null);

  const { register, handleSubmit, control, errors } = useForm()
  const addProgram = async (data) => {
    setLoading(true);
    let createprogram = await createProgramme(data)
    if (createprogram.data.success === true) {
      setLoading(false);
      window.document.location.reload()
    }
    else{
      setError("adding Program failed please try again");
      setLoading(false);
  }
    setLoading(false);
  }

  return (
    <div className="programlist-container">
      <div className="programlist" >
      {error && (
          <Slide
            in={true}
            direction="down"
            timeout={{ enter: 500, exit: 500 }}
            onEntered={() => setTimeout(() => setError(null), 2000)}
            style={{
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              left:"30%",
              margin:"0px auto",
              top: 140,
              position: "fixed"
            }}
          >
            <div 
            style={ {
              backgroundColor: "#DC3545",
              padding: 20,
              color: "white",
              borderRadius: 4,
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >{error}</div>
          </Slide>
        )}
        {programlist && <MaterialTable
          columns={[
            { title: "Title", field: "name" },
            {
              title: "Project",
              field: "title",
              editComponent: ({ onRowDataChange, rowData }) => {
                const onChangeHandler = e => {
                  onRowDataChange({
                    ...rowData,
                    project_id: e.target.value
                  });
                };
                return (
                  <select value={rowData.project_id} onChange={(e) => onChangeHandler(e)}>
                    {projectlist.map((item, index) => {
                      return (
                        <option value={item.id}>{item.title}</option>
                      )
                    })}
                  </select>
                );
              }
            },
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              fetch(config.ourProgramme + `/${query.pageSize}?page=${query.page}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
                }
              })
                .then(response => response.json())
                .then(result => {
                  if (result.success) {
                    resolve({
                      data: result.data.data,
                      page: query.page,
                      totalCount: result.data.total
                    });
                  } else {
                    reject(console.log("error"));
                  }
                });
            })
          }
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                fetch(config.editprogramme + oldData.id, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                  },
                  body: JSON.stringify({
                    name: newData.name,
                    project_id: newData.project_id
                  })
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success) {
                      resolve();
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
          }}
          title="Current Program"
          options={{
            paging: true,
            emptyRowsWhenPaging: false,
            search: false,
            actionsColumnIndex: -1
          }}
        />}

        <div className="programlist-add"
          style={{ background: "white", padding: 20, borderRadius: 8, boxShadow: "0px 3px 10px -5px gray" }}
        >
          <div className="text-thin text-bold">
            Create Program
                    </div>
          <form onSubmit={handleSubmit(addProgram)}>
            <div className="programlist-add-text mt-3">
              <TextField
                name="name"
                inputRef={register({ required: true })}
                variant="outlined"
                fullWidth
                placeholder="Add Program"
                required
              />

              <Controller
                as={<ReactSelect options={projectlist} placeholder="Select Project" required />}
                name="id"
                isClearable
                rules={{ required: true }}
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
              />
              {errors.id &&
                <div style={{ height: 50, width: 200, boxShadow: "0px 0px 10px 0px #c4c2c2", borderRadius: 8, position: "relative" }}>
                  <div style={{ position: "absolute", height: 20, width: 20, backgroundColor: "white", top: -5, left: 20, transform: "rotate(45deg)" }}></div>

                  <div style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <span className="text-red text-bold"> Project is Required </span>
                  </div>

                </div>}
                {loading?<div style={{width:"100%",display:"grid",justifyItems:"center",alignItems:"center"}}><CircularProgress/></div>:
              <Button
                style={{ height: "100%" }}
                type="submit"
                variant="contained">Add</Button>}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

ProgramList.propTypes = {
  programlist: PropTypes.array.isRequired,
  projectlist: PropTypes.array.isRequired,
};

export default ProgramList