import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isLoggedIn } from "./Utils";

export const PublicLink = ({ name, path, restricted, visible, children }) => {
  return restricted && isLoggedIn() ? 
    null : visible ? children ? children : <Link to={path}>{ name }</Link> : null
}

PublicLink.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  restricted: PropTypes.bool.isRequired,
  visible: PropTypes.bool,
  children: PropTypes.node
};

PublicLink.defaultProps = {
  visible: true,
  children: null
};

export const PrivateLink = ({ name, path, visible, children }) => {
  return isLoggedIn() ? 
    visible ? children ? children : <Link to={path}>{ name }</Link> : null : null
}

PrivateLink.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  visible: PropTypes.bool,
  children: PropTypes.node
};

PrivateLink.defaultProps = {
  visible: true,
  children: null
};
