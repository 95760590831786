import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../utils/Utils";

// PRIVATE ROUTES
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route
    {...rest}
    render = {props => {
      return isLoggedIn() ?
        <Component {...props} /> : <Redirect to="/log-in" />
    }}
  />
}

// PUBLIC OR RESTRICTED ROUTES
export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return <Route 
    {...rest}
    render = {props => {
      return isLoggedIn() && restricted ?
        <Redirect to="/dashboard" /> : <Component {...props} />
    }}
  />
}