import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const addStories = async (data,image,thumbnail) => {
    let formData = new FormData();
    let images = image[0]
    for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i])
    }
    await formData.append('title', data.title)
    await formData.append('description', data.description)
    await formData.append('thumbnail',thumbnail[0])

    return axios.post(config.postStories, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}



export const editStories = async (data,id,image,thumbnail) => {
    let formData = new FormData();
    if(image.length>0){
        let images = image[0]
        for (let i = 0; i < images.length; i++) {
            formData.append('images[]', images[i])
        }
    }
    await formData.append('title', data.title)
    await formData.append('description', data.description)
    
    if(typeof(thumbnail)==="string"){
        await formData.append('thumbnail',thumbnail)
    }else if(typeof(thumbnail)==="object"){
        await formData.append('thumbnail',thumbnail[0])
    }

    return axios.post(config.editStories+id, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const deleteStoriesImage = async (id) => {

    return axios.delete(config.deleteStoriesImage + id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}