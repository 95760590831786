import React from "react";
import { Redirect } from "react-router-dom";
// ALL ROUTES, PATH AND RESTRICTION ARE ADDED HERE

// ROUTES
import LoginPage from "../loginPage/LoginPage";
import DashboardPage from "../dashboardPage/DashboardPage";
import OurWorkPage from "../ourWorkPage/OurWorkPage";
import StoriesPage from "../storiesPage/StoriesPage";
import ActivitiesPage from "../activitiesPage/ActivitiesPage";
import PushpaVideoPage from "../pushpaVideoPage/PushpaVideoPage";
import PushpaNewsPage from "../pushpaNewsPage/PushpaNewsPage";
import AchievementPage from "../achievementPage/AchievementPage";
import MapPage from ".././mapPage/MapPage";
import WishlistPage from "../wishlistPage/WishlistPage";
import HistoryTimeline from "../historyTimelinePage/HistoryTimelinePage";
import PressAndMediaPage from "../pressAndMediaPage/PressAndMediaPage";
import VolunteerPage from "../volunteerPage/VolunteerPage";
import NewsLetterPage from "../newsletterPage/NewsletterPage";
import ProductsPage from "../productsPage/ProductsPage.component";
import OrderPage from "../orderPage/OrderPage.component";
import FundraiserPage from "../fundraiserPage/FundraiserPage";
// import SettingsPage from "../settingsPage/SettingsPage";

// REDIRECT TO DASHBAORD COMPONENT
const RedirectToDashboard = () => {
  return (
    <Redirect to="/dashboard" />
  )
} 

// PUBLIC PATHS => THESE ARE ACCESSIBLE WITHOUT LOGIN
// PUBLIC PATHS HAS restricted key => IF TRUE THEY CANNOT BE ACCESSED WITH LOGGED USER
export const PUBLIC_PATHS = [
  {
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    exact: true,
    restricted: true,
    visible: true
  }
];

// PUBLIC PATHS => THESE ARE NOT ACCESSIBLE WITHOUT LOGIN
export const PRIVATE_PATHS = [
  {
    name: "Dashboard",
    path: "/",
    exact: true,
    component: RedirectToDashboard,
    visible: false
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    exact: true,
    component: DashboardPage,
    visible: true
  },
  {
    name: "Our Work",
    path: "/our-work",
    exact: true,
    component: OurWorkPage,
    visible: true
  },
  {
    name: "Stories",
    path: "/stories",
    exact: true,
    component: StoriesPage,
    visible: true
  },
  {
    name: "Achievements",
    path: "/achievements",
    exact: true,
    component: AchievementPage,
    visible: true
  },
  {
    name: "Activities",
    path: "/activities",
    exact: true,
    component: ActivitiesPage,
    visible: true
  },
  {
    name: "Pushpa's Videos",
    path: "/pushpa-video",
    exact: true,
    component: PushpaVideoPage,
    visible: true
  },
  {
    name: "Pushpa's News",
    path: "/pushpa-news",
    exact: true,
    component: PushpaNewsPage,
    visible: true
  },
  {
    name: "NewsLetter",
    path: "/newsletter",
    exact: true,
    component: NewsLetterPage ,
    visible: true
  },
  {
    name: "Map",
    path: "/map",
    exact: true,
    component: MapPage,
    visible: true
  },
  {
    name: "Wishlist",
    path: "/wishlist",
    exact: true,
    component: WishlistPage,
    visible: true
  },
  {
    name: "History Timeline",
    path: "/history-timeline",
    exact: true,
    component: HistoryTimeline,
    visible: true
  },
  {
    name: "Press and Media",
    path: "/press-media",
    exact: true,
    component: PressAndMediaPage,
    visible: true
  },
  {
    name: "Volunteer",
    path: "/volunteer",
    exact: true,
    component: VolunteerPage,
    visible: true
  },
  {
    name: "Butterfly Products",
    path: "/products",
    exact: true,
    component: ProductsPage,
    visible: true
  },
  {
    name: "Orders",
    path: "/orders",
    exact: true,
    component: OrderPage,
    visible: true
  },
  {
    name: "Fundraiser",
    path: "/fundraiser",
    exact: true,
    component: FundraiserPage,
    visible: true
  },
  // {
  //   name: "Settings",
  //   path: "/settings",
  //   exact: true,
  //   component: SettingsPage,
  //   visible: true
  // }
];