export const server = "http://api.ecdcnepal.org/api/"; //LIVE API
// export const server = "http://192.168.1.130/ecdcBackend/api/"; //ANKIT API
// export const server = "http://192.168.43.27:8000/api/"; //ANKIT API

export const config = {
    volunteers: server + 'volunteers',
    press: server + 'media',
    timeline: server + 'timeline',
    wishlist: server + 'wishlist',
    achievements: server + 'achievements',
    achievementEdit: server + 'achievements/',
    district: server + 'districtAll',
    mapData: server + 'maps/',
    editMapPut: server + 'addmapdata/',
    addMapPost: server + 'addmapdata',
    login: server + 'login',
    ourwork: server + 'ourwork',
    createProject: server + "createProject",
    createProgramme: server + "createProgramme",
    ourProgramme: server + 'programme',
    deleteTimeline: server + 'deletetimeline/',
    deleteMedia: server + 'deletemedia/',
    deleteVolunteers: server + 'volunteers/',
    deleteWishlist: server + 'wishlist/',
    pushpaVideos: server + 'pushpaVideos',
    deletePushpaVideos: server + 'pushpaVideos/',
    editPushpaVideos: server + 'pushpaVideos/',
    getStories: server + 'getStories',
    postStories: server + 'stories',
    deleteStories: server + 'stories/',
    addArticles: server + "addArticles",
    listArticles: server + "listArticles",
    deleteArticles: server + "deleteArticle/",
    addWishlist: server + "wishlist",
    addTimeline: server + 'timeline',
    addPress: server + 'addmedia',
    activities: server + 'activities',
    deleteActivities: server + 'deleteActivities/',
    deleteActivitiesImage: server + 'deleteActivitiesImage/',
    deleteArticleImage: server + 'deleteArticleImage/',
    deleteStoriesImage: server + 'storiesImage/',
    editStories: server + "editStories/",
    editActivities: server + "editactivities/",
    editWishList: server + "editwishlist/",
    editArticles: server + 'editArticles/',
    editPressnMedia: server + "editMedia/",
    editTimeline: server + 'editTimeLine/',
    editprogramme: server + 'editprogramme/',
    editProject: server + 'editProject/',
    pushpanews: server + 'pushpanews',
    editpushpanews: server + 'editpushpanews/',
    deletepushpanews: server + 'deletepushpanews/',
    subscribers: server + 'subscribers',
    deletesubscribers: server + 'deletesubscribers/',
    newsletter: server + 'newsletter',
    ourProducts: server + 'products',
    editProducts: server + 'updateproduct/',
    deleteProduct: server + 'deleteproduct/',
    deleteProductImage: server + 'deleteProductImage/',
    orders: server + 'ecommercebuyer/',
    changePass: server + 'changePw',
    fundraiser: server + 'fundraiser/'
}