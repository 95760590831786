import React, { useState } from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import { config } from "../services/config";

// COMPONENTS 
import AddStories from "./addStories/AddStories.component";
import EditStories from "./editStories/EditStories.component";

// SERVICES 
import {deleteStoriesImage} from "../services/stories/Stories";

// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");



const StoriesPage = () => {
  const [storiesEdit, setStoriesEdit] = useState();  
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div style={{ position: "relative", zIndex: 0 }}>
          <a href="#add">
            <div className="table-addbutton text-rumpel">
              ADD
            </div>
          </a>
          <MaterialTable
            columns={[
              {
                title: "Thumbnail",
                field: "thumbnail",
                render: rowData => (
                  <img
                    alt="StoriesImage"
                    style={{ width: 250, borderRadius: 4 }}
                    src={rowData.thumbnail}
                  />
                )
              },
              { title: "Title", field: "title" },
              { title: "Description", field: "shortDescription" }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                fetch(config.getStories + `/${query.pageSize}?page=${query.page + 1 }`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                  }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success || result.success === false) {
                      resolve({
                        data: result.data.data,
                        page: query.page,
                        totalCount: result.data.total
                      });
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
            }
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  fetch(config.deleteStories + oldData.id, {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                }),
            }}
            detailPanel={rowData => {
              const deleteHandler = async (id) =>{
                let deleteImageResponse = await deleteStoriesImage(id);
                if(deleteImageResponse.data.success===true){
                  window.document.location.reload()
              }
                
              }
              return (
                <div
                  style={{
                    padding: 20,
                    display: "grid",
                    gridTemplateColumns: "repeat(9, 1fr)"
                  }}
                >
                  {rowData.images &&
                    rowData.images.map((item, index) => {
                      return (
                        <div
                          style={{
                            position: "relative",
                            backgroundImage: `url(${item.image_url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            gridTemplateColumns: "1fr",
                            height: 100,
                            width: 100
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 10,
                              color: "red",
                              cursor: "pointer",
                              fontSize: 20
                            }}
                            className="font-averta text-bold"
                            onClick={() => deleteHandler(item.id)}
                          >
                            x
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  setStoriesEdit(rowData)
                  handleClickOpen()
                  // document.getElementById('add-article').scrollIntoView()
    
                  // props.history.push("/our-work/", {
                  //   editData: rowData
                  // });
                }
              }
            ]}
            title="Stories"
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false
            }}
          />
        </div>
        <div id="add" style = {{paddingTop: 150}}>
            {/* ADD HERE  */}
            <AddStories/>
        </div>

        <EditStories storyValue={storiesEdit} openStatus={open} onCloseClick={handleClose} />
      </div>
    </div>
  );
};

export default StoriesPage;
