import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from 'react-hook-form'
import { TextField,CircularProgress, Slide } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ReactSelect from "react-select";

import {addArticles } from "../../services/ourWork/ourWork"

import { DefaultButton, InvertedButton } from "../../common/button/Button.common"


const AddArticle = ({programlist, ourProps}) => {
    const { register, handleSubmit, control, errors } = useForm()

    const [error, setError] = React.useState(null);
    const [loading,setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [image, setImage] = useState([])
    const [thumbnail, setThumbnail] = useState()
    const [showThumbnailError, setShowThumbnailError] = useState(false)
    const [showImageError, setShowImageError] = useState(false)
    
        const onSubmit = async(data) => {
           if( image.length&&image[0]?.length&&(!(thumbnail===undefined||thumbnail[0]===undefined))){
            setLoading(true);
             let addarticle = await addArticles(data,image,thumbnail)
             if(addarticle.data.success===true){
                setLoading(false);
                 window.document.location.reload()
             }
             else{
                setError("adding  article failed please try again");
                setLoading(false);
             }
             setLoading(false);
           }
           if(image.length===0||image[0].length===0){    
                setShowImageError(true)
            }
            if(thumbnail===undefined||thumbnail[0]===undefined)
            {    
                setShowThumbnailError(true)
            }
        }

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const onChangeImage = (event) => {
        setImage([ event.target.files]);
        setShowImageError(false)
    }

    const onChangeThumbnail = (event) => {
        setThumbnail([ event.target.files[0]]);
        setShowThumbnailError(false)
    }

    return (
        <div className="addarticle-container">
            <div className="addarticle" style={{ background: "white", padding: 20, borderRadius: 8, boxShadow: "0px 3px 10px -5px gray" }}>
            {error && (
          <Slide
            in={true}
            direction="down"
            timeout={{ enter: 500, exit: 500 }}
            onEntered={() => setTimeout(() => setError(null), 2000)}
            style={{
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              left:"30%",
              margin:"0px auto",
              top: 140,
              position: "fixed"
            }}
          >
            <div 
            style={ {
              backgroundColor: "#DC3545",
              padding: 20,
              color: "white",
              borderRadius: 4,
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >{error}</div>
          </Slide>
        )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: 10 }}>
                        <div className="text-thin text-bold text-black">
                            Add Article
                        </div>

                        <TextField name="title" inputRef={register({ required: true })} label="Title" variant="outlined" required />

                        <TextField name="description" multiline rows="4" inputRef={register({ required: true })} label="Description" variant="outlined" required />

                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Article Date"
                                format="yyyy-MM-dd"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                name="date" inputRef={register({ required: true })}
                            />
                        </MuiPickersUtilsProvider>

                        <Controller
                            as={<ReactSelect options={programlist} placeholder="Select Program" required />}
                            name="program"
                            isClearable
                            rules={{ required: true }}
                            control={control}
                            onChange={([selected]) => {
                                return { value: selected };
                            }} />

                            {errors.program && 
                            <div style={{height:50,width:200,boxShadow:"0px 0px 10px 0px #c4c2c2",borderRadius:8,position:"relative"}}>
                                <div style={{position:"absolute",height:20,width:20,backgroundColor:"white",top:-5,left:20,transform:"rotate(45deg)"}}></div>
                                
                                <div  style={{position:"absolute",width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <span className="text-red text-bold"> Program is Required </span>
                                </div>

                            </div> } 

                        <div style={{display:"flex",flexDirection:"row", alignItems:"center"}}>
                            <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                                <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" multiple name="thumbnail" onChange={(event) => { onChangeImage(event) }} />
                                <DefaultButton title="Add Photos" />
                            </div>

                            {image[0]?.length &&
                                <div className="text-rumpel">
                                    {image[0]?.length} Images selected
                            </div>}
                        </div>
                        {showImageError && 
                            <div style={{height:50,width:200,boxShadow:"0px 0px 10px 0px #c4c2c2",borderRadius:8,position:"relative"}}>
                                <div style={{position:"absolute",height:20,width:20,backgroundColor:"white",top:-5,left:20,transform:"rotate(45deg)"}}></div>
                                
                                <div  style={{position:"absolute",width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <span className="text-red text-bold"> Image is Required </span>
                                </div>

                            </div> } 
                        
                        <div style={{display:"flex",flexDirection:"row", alignItems:"center"}}>
                            <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                                <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" name="thumbnail" onChange={(event) => { onChangeThumbnail(event) }} />
                                <DefaultButton title="Add thumbnail" />
                            </div>
                            {thumbnail&&
                                <div className="text-rumpel">
                                    {thumbnail[0]?.name}
                                </div>
                            }
                        </div>
                        {showThumbnailError && 
                            <div style={{height:50,width:200,boxShadow:"0px 0px 10px 0px #c4c2c2",borderRadius:8,position:"relative"}}>
                                <div style={{position:"absolute",height:20,width:20,backgroundColor:"white",top:-5,left:20,transform:"rotate(45deg)"}}></div>
                                
                                <div  style={{position:"absolute",width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <span className="text-red text-bold"> Thumbnail is Required </span>
                                </div>

                            </div> } 
                        {loading?<div style={{width:"100%",display:"grid",justifyItems:"center",alignItems:"center"}}><CircularProgress/></div>:
                        <InvertedButton type="submit" title="Submit"/>                        
                    }
                    </div>
                </form>
            </div>
        </div>
    )
}

AddArticle.propTypes = {
    programlist: PropTypes.array.isRequired,
};

export default AddArticle