import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const changePassword = async (data) => {
    return axios.post(config.changePass, {
        old_password: data.old_password,
        password: data.new_password,
        c_password: data.confirm_password
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(function (response) {
        return response
    })
    .catch(function (error) {
        console.log('error', error);
    })
}
