import React from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import { config } from "../services/config";
// COMPONENTS
import SendNewsletter from './sendNewsletter/SendNewsletter.component';

// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");



const NewsLetterPage = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard">
          <MaterialTable
            columns={[
              { title: "Email", field: "email" }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                fetch(config.subscribers + `/${query.pageSize}?page=${query.page + 1 }`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                  }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success) {
                      resolve({
                        data: result.data.data,
                        page: query.page,
                        totalCount: result.data.total
                      });
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
            }
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  fetch(config.deletesubscribers + oldData.id, {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                })
            }}
            title="Subscribers"
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false,
            }}
          />
          <SendNewsletter />
      </div>
    </div>
  );
};

export default NewsLetterPage;
