import React, { useState } from "react";
import { useForm} from 'react-hook-form'
import { TextField, Slide, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Draggable from 'react-draggable';

import { editTimeline } from "../../services/timeline/Timeline"

import { DefaultButton } from "../../common/button/Button.common"
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const EditHistoryTimeline = ({ openStatus, historyTimelineValue, onCloseClick }) => {
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [image, setImage] = useState()
    const [error, setError] = React.useState(null);
    const { register, handleSubmit } = useForm()
    const onSubmit = async (data) => {
        setLoading(true);
        let updateTimeline = await editTimeline(data, historyTimelineValue?.id, image ? image : historyTimelineValue?.image)
        if (updateTimeline.data.success === true) {
            setLoading(false);
            window.document.location.reload()
        }
        else {
            setError("Adding History failed please try again");
            setLoading(false);
        }
        setLoading(false);
    }

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const onChangeImage = (event) => {
        setImage([event.target.files[0]]);
    }
    return (<Dialog
        open={openStatus}
        onClose={onCloseClick}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Edit History/Timeline
        </DialogTitle>
        {error && (
          <Slide
            in={true}
            direction="down"
            timeout={{ enter: 500, exit: 500 }}
            onEntered={() => setTimeout(() => setError(null), 2000)}
            style={{
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              left:"30%",
              margin:"0px auto",
              top: 140,
              position: "fixed"
            }}
          >
            <div 
            style={ {
              backgroundColor: "#DC3545",
              padding: 20,
              color: "white",
              borderRadius: 4,
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >{error}</div>
          </Slide>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
            
            <DialogContent>
            <div style={{ width:500, display: "grid",gridTemplateColumns:"1fr", gridRowGap: 10 }}>
                <TextField
                fullWidth
                    defaultValue={historyTimelineValue ? historyTimelineValue.title : "no data"}
                    name="title" inputRef={register({ required: true })} label="Title" variant="outlined" required />

                <TextField
                    defaultValue={historyTimelineValue ? historyTimelineValue.description : "no data"}
                    name="description" multiline rows="4" inputRef={register({ required: true })} label="Description" variant="outlined" required />

                 <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Article Date"
                        format="yyyy-MM-dd"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        name="date" inputRef={register({ required: true })}
                    />
                </MuiPickersUtilsProvider>


                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                        <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" multiple name="thumbnail" onChange={(event) => { onChangeImage(event) }} />
                        <DefaultButton title="Add Photos" />
                    </div>

                    {image &&
                        <div className="text-rumpel">
                            {image[0]?.name} 
                            </div>}
                </div>

           
                {/* {loading ? <div style={{ width: "100%", display: "grid", justifyItems: "center", alignItems: "center" }}><CircularProgress /></div> :
                    <InvertedButton type="submit" title="Submit" />
                } */}
</div>
            </DialogContent>

            {loading ? <div style={{ width: "100%", marginBottom: "20px", display: "grid", justifyItems: "center", alignItems: "center" }}><CircularProgress /></div> :
                <DialogActions>
                    <Button autoFocus onClick={onCloseClick} variant="contained" color="primary">
                        Cancel
                        </Button>
                    <Button type="submit" color="primary" variant="contained">
                        Update
                        </Button>
                </DialogActions>
            }
        </form>
    </Dialog>
    )
}

export default EditHistoryTimeline;