import React, { useState } from "react";
import { useForm } from 'react-hook-form'
import Cookies from "universal-cookie";
import { Slide, CircularProgress } from "@material-ui/core/";

// SERVICES
import { login } from "../services/login/Login";

// IMPORT COMMONS
import { InvertedButton } from "../common/button/Button.common";

// IMPORT IMAGES
import UsernameLogo from "../../assets/icons/Username.png";
import PasswordLogo from "../../assets/icons/Password.png";

// CREATE COOKIE
const cookie = new Cookies();


const LoginPage = () => {
  const { register, handleSubmit } = useForm()
  const [error, setError] = React.useState(null);
  const [loading,setLoading] = useState(false)

  const handleLogin = async (data) => {
    setLoading(true);
    let loginRequest = await login(data.email, data.password);
    if(loginRequest.success === true){
      setLoading(false);
      // SET COOKIES
      let date = 3600;
      cookie.set("browser_ID", loginRequest.data.token, {maxAge: date});
      window.document.location.reload();
    }
    else{
      setLoading(false);
      setError(loginRequest.error);
    }
    setLoading(false);
  }

  return (
    <div className="login-container">
      <div className="login">
      {error && (
          <Slide
            in={true}
            direction="down"
            timeout={{ enter: 500, exit: 500 }}
            onEntered={() => setTimeout(() => setError(null), 3000)}
            style={{
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              left:"30%",
              margin:"0px auto",
              top: 140,
              position: "fixed"
            }}
          >
            <div 
            style={ {
              backgroundColor: "#DC3545",
              padding: 20,
              color: "white",
              borderRadius: 4,
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >{error}</div>
          </Slide>
        )}

        <div className="login-title">
          MEMBER LOGIN
        </div>
        <form onSubmit={handleSubmit(handleLogin)}>
        <div className="login-input">
          <div className="login-input-logo">
            <img src={UsernameLogo} alt="USERNAME" />
          </div>

          <input 
            // onChange = {(e) => setEmail(e.target.value)} 
            type="text" name="email" ref={register({ required: true })} className="login-input-field" placeholder="Username" />
        </div>

        <div className="login-input">
          <div className="login-input-logo">
            <img src={PasswordLogo} alt="PASSWORD" />
          </div>

          <input 
          // onChange = {(e) => setPassword(e.target.value)} 
          type="password" name="password" className="login-input-field" ref={register({ required: true })} placeholder="Password" />
        </div>

        <div className="login-submit">
          {loading?<div style={{width:"100%",display:"grid",justifyItems:"center",alignItems:"center"}}><CircularProgress/></div>:
          <InvertedButton title="LOGIN" style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }} 
          // onClick={() => handleLogin()} 
          />
        }
        </div>
      </form>
      </div>
    </div>
  )
}

export default LoginPage;