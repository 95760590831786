import React, { useState } from "react";
import { useForm, Controller } from 'react-hook-form'
// import MaterialTable from "material-table";
// import Cookies from "universal-cookie";
import { TextField, CircularProgress, Slide, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ReactSelect from "react-select";
import Draggable from 'react-draggable';


// SERVICES 
import { editArticles } from "../../services/ourWork/ourWork";

import { DefaultButton } from "../../common/button/Button.common"
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const EditArticle = ({ openStatus, editValue, onCloseClick, programlist }) => {
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [image, setImage] = useState([])
    const [thumbnail, setThumbnail] = useState()
    const [error, setError] = React.useState(null);

    const { register, handleSubmit, control, errors } = useForm()

    const onSubmit = async (data) => {
        setLoading(true);
        let updateArticles = await editArticles(data, editValue?.id, image, thumbnail ? thumbnail : editValue?.thumbnail)
        setLoading(false);
        if (updateArticles.data.success === true) {
            window.document.location.reload()
            onCloseClick()
        }else{
            setError("editing  article failed please try again");
            setLoading(false);
        }
        setLoading(false);
    }

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const onChangeImage = (event) => {
        setImage([event.target.files]);
    }

    const onChangeThumbnail = (event) => {
        setThumbnail([event.target.files[0]]);
    }

    return (<Dialog
        open={openStatus}
        onClose={onCloseClick}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Edit Article
        </DialogTitle>
        {error && (
          <Slide
            in={true}
            direction="down"
            timeout={{ enter: 500, exit: 500 }}
            onEntered={() => setTimeout(() => setError(null), 2000)}
            style={{
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              left:"30%",
              margin:"0px auto",
              top: 140,
              position: "fixed"
            }}
          >
            <div 
            style={ {
              backgroundColor: "#DC3545",
              padding: 20,
              color: "white",
              borderRadius: 4,
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >{error}</div>
          </Slide>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>

            <DialogContent>
                <div style={{ width: 500, display: "grid", gridTemplateColumns: "1fr", gridRowGap: 10 }}>
                    <TextField
                        fullWidth
                        defaultValue={editValue ? editValue.title : "no data"}
                        name="title" inputRef={register({ required: true })} label="Title" variant="outlined" required />

                    <TextField
                        defaultValue={editValue ? editValue.description : "no data"}
                        name="description" multiline rows="4" inputRef={register({ required: true })} label="Description" variant="outlined" required />

                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Article Date"
                            format="yyyy-MM-dd"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            name="date" inputRef={register({ required: true })}
                        />
                    </MuiPickersUtilsProvider>

                    <Controller
                        as={<ReactSelect options={programlist} placeholder="Program" required />}
                        name="program"
                        isClearable
                        rules={{ required: true }}
                        control={control}
                        defaultValue={{ label: editValue?.name, id: editValue?.programme_id }}
                        onChange={([selected]) => {
                            return { value: selected };
                        }} />
                    {errors.program &&
                        <div style={{ height: 50, width: 200, boxShadow: "0px 0px 10px 0px #c4c2c2", borderRadius: 8, position: "relative" }}>
                            <div style={{ position: "absolute", height: 20, width: 20, backgroundColor: "white", top: -5, left: 20, transform: "rotate(45deg)" }}></div>

                            <div style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <span className="text-red text-bold"> Program is Required </span>
                            </div>

                        </div>}

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                            <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" multiple name="thumbnail" onChange={(event) => { onChangeImage(event) }} />
                            <DefaultButton title="Add Photos" />
                        </div>

                        {image[0]?.length &&
                            <div className="text-rumpel">
                                {image[0]?.length} Images selected
                        </div>}
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                            <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" name="thumbnail" onChange={(event) => { onChangeThumbnail(event) }} />
                            <DefaultButton title="Add thumbnail" />
                        </div>
                        {thumbnail &&
                            <div className="text-rumpel">
                                {thumbnail[0]?.name}
                            </div>
                        }
                    </div>
                </div>
            </DialogContent>

            {loading ? <div style={{ width: "100%", marginBottom: "20px", display: "grid", justifyItems: "center", alignItems: "center" }}><CircularProgress /></div> :
                <DialogActions>
                    <Button autoFocus onClick={onCloseClick} variant="contained" color="primary">
                        Cancel
                        </Button>
                    <Button type="submit" color="primary" variant="contained">
                        Update
                        </Button>
                </DialogActions>
            }
        </form>
    </Dialog>
    )
}

export default EditArticle;