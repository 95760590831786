import React, { useState } from "react";
import { useForm } from 'react-hook-form'
import { TextField, Slide, CircularProgress } from '@material-ui/core';

import { sendNewsletter } from "../../services/newsletter/Newsletter";

import { DefaultButton, InvertedButton } from "../../common/button/Button.common"


const SendNewsletter = () => {
    const { register, handleSubmit } = useForm()
    const [loading,setLoading] = useState(false)
    const [error, setError] = React.useState(null);
    const onSubmit = async(data) => {
        if( thumbnail.length ){
        setLoading(true);
         let sendnewsletter = await sendNewsletter(data,thumbnail)
         console.log('Response', sendnewsletter)
         if(sendnewsletter.data.success===true){
            setLoading(false);
             window.document.location.reload()
            }
            else{
                setError("Sending newsletter has failed please try again");
                setLoading(false);
             }
             setLoading(false);
        }
    }

    const [thumbnail, setThumbnail] = useState([])

    const onChangeThumbnail = (event) => {
        setThumbnail([ event.target.files[0]]);
    }

    return (
        <div className="addarticle-container mt-5">
            <div className="addarticle" style={{ background: "white", padding: 20, borderRadius: 8, boxShadow: "0px 3px 10px -5px gray" }}>
            {error && (
          <Slide
            in={true}
            direction="down"
            timeout={{ enter: 500, exit: 500 }}
            onEntered={() => setTimeout(() => setError(null), 2000)}
            style={{
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              left:"30%",
              margin:"0px auto",
              top: 140,
              position: "fixed"
            }}
          >
            <div 
            style={ {
              backgroundColor: "#DC3545",
              padding: 20,
              color: "white",
              borderRadius: 4,
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >{error}</div>
          </Slide>
        )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: 10 }}>
                        <div className="text-thin text-bold text-black mb-4">
                            Send Newsletter
                        </div>

                        <TextField name="subject" inputRef={register({ required: true })} label="Subject" variant="outlined" required />

                        <TextField name="description" inputRef={register({ required: true })} label="Description" variant="outlined" required />


                        <div style={{display:"flex",flexDirection:"row", alignItems:"center"}}>
                            <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                                <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" name="thumbnail" onChange={(event) => { onChangeThumbnail(event) }} />
                                <DefaultButton title="Add image" />
                            </div>
                            {thumbnail.length!==0 &&
                                <div className="text-rumpel">
                                    {thumbnail[0].name}
                                </div>
                            }
                        </div>
                        {loading?<div style={{width:"100%",display:"grid",justifyItems:"center",alignItems:"center"}}><CircularProgress/></div>:
                        <InvertedButton type="submit" title="Submit"/>                        
                    }
                    </div>
                </form>
            </div>
        </div>
    )
}


export default SendNewsletter;