import React from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import { config } from "../services/config";

// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");


const PushpaNewsPage = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard">
          <MaterialTable
            columns={[
              { title: "Title", field: "title" },
              { title: "Date", field: "post_date" },
              {title: "Photo URL", field: "photo_url", render: rowData => <a target="_blank" rel="noopener noreferrer" href = {rowData.photo_url}>{(rowData.photo_url.slice(0,20)) + "..."}</a>},
              {title: "Media URL", field: "media_url", render: rowData => <a target="_blank" rel="noopener noreferrer" href = {rowData.media_url}>{(rowData.media_url.slice(0,20)) + "..."}</a>},

            ]}
            data={query =>
              new Promise((resolve, reject) => {
                fetch(config.pushpanews + `/${query.pageSize}?page=${query.page + 1 }`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                  }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success || result.success === false) {
                      resolve({
                        data: result.data.data,
                        page: query.page,
                        totalCount: result.data.total
                      });
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
            }
            detailPanel={rowData => {
                return (
                    <div style = {{padding: 20}}>
                        <div
                            style={{
                                position: "relative",
                                backgroundImage: `url(${rowData.photo_url})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: 100,
                                width: 100,
                                padding: 20
                            }}
                        />
                    </div>
                );
              }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  fetch(config.pushpanews , {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({
                      title: newData.title,
                      post_date: newData.post_date,
                      media_url: newData.media_url,
                      photo_url: newData.photo_url
                    })
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
              }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  fetch(config.deletepushpanews + oldData.id, {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                }),
                onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  fetch(config.editpushpanews + oldData.id, {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        title: newData.title,
                        post_date: newData.post_date,
                        media_url: newData.media_url,
                        photo_url: newData.photo_url
                    })
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                })
            }}
            title="Pushpa's News"
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false,
            }}
          />
      </div>
    </div>
  );
};

export default PushpaNewsPage;
