import React from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import {config} from "../services/config";

// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");

const VolunteerPage = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard">
      <MaterialTable
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Phone', field: 'phone_number' },
        { title: 'Email', field: 'email' },
        { title: 'Contribution', field: 'contribution',  render: rowData => (rowData.contribution.slice(0,20)) + "..." },
        { title: 'Experience', field: 'experience', render: rowData => (rowData.experience.slice(0,20)) + "..." },
        { title: 'Plan', field: 'description', render: rowData => (rowData.description.slice(0,20)) + "..." },
      ]}
      data={query =>
        new Promise((resolve, reject) => {
          fetch(config.volunteers+ `/${query.pageSize}?page=${query.page + 1 }`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          })
            .then(response => response.json())
            .then(result => {
              if (result.success) {
                resolve({
                  data: result.data.data,
                  page: query.page,
                  totalCount: result.data.total
                });
              } else {
                reject(console.log("error"));
              }
            });
        })
      }
      title="Volunteer Applications"
      detailPanel={rowData => {
        return (
          <div style = {{padding: 20}}>
            <div style = {{marginBottom: 16}}>
              <h3>Contributions</h3>
              <p>{rowData.contribution}</p>
            </div>
            <div style = {{marginBottom: 16}}>
              <h3>Experience</h3>
              <p>{rowData.experience}</p>
            </div>
            <div style = {{marginBottom: 16}}>
              <h3>Plans</h3>
              <p>{rowData.description}</p>
            </div>
          </div>
        )
      }}
      editable={{
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            fetch(config.deleteVolunteers + oldData.id, {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.success) {
                  resolve();
                } else {
                  reject(console.log("error"));
                }
              });
          }),
      }}
      actions={[
        {
          icon: 'cloud_download',
          tooltip: 'Download CV',
          onClick: (event, rowData) => document.location.href = `${rowData.cv}`
        }
      ]}
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        search: false,
        exportButton: true,
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
      
      </div>
    </div>
  )
}

export default VolunteerPage;