import React from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
// IMPORT ICONS
import { 
  MdDashboard,
  MdTimeline,
  MdPermMedia,
  MdSettings,
  MdOndemandVideo
} from "react-icons/md";
import { IoMdConstruct } from "react-icons/io";
import { 
  FaBook, 
  FaMedal, 
  FaRegNewspaper, 
  FaMapMarkedAlt,
  FaHeart,
  FaHandsHelping
} from "react-icons/fa";
import { FiActivity } from "react-icons/fi";

const SELECT_ICONS = {
  "Dashboard": <MdDashboard />,
  "Our Work": <IoMdConstruct size = "2em"/>,
  "Stories": <FaBook size = "2em" />,
  "Achievements": <FaMedal size = "2em" />,
  "Activities": <FiActivity size = "2em" />,
  "Pushpa's Videos": <MdOndemandVideo size = "2em" />,
  "Pushpa's News": <FaRegNewspaper size = "2em" />,
  "NewsLetter": <FaRegNewspaper size = "2em" />,
  "Map": <FaMapMarkedAlt size = "2em"/>,
  "Wishlist": <FaHeart size = "2em" />,
  "History Timeline": <MdTimeline size = "2em"/>,
  "Press and Media": <MdPermMedia size = "2em"/>,
  "Volunteer": <FaHandsHelping size = "2em"/>,
  "Settings": <MdSettings size = "2em"/>
};



const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 20
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    height: 180,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    gridTemplateColumns: '1fr',
    fontSize: 24,
    color: '#353535'
  },
  icons: {
    paddingTop: 6,
    marginRight: 25
  }
}));

const LINKS = [
  {title: 'Our Work', link: '/our-work'},
  {title: 'Stories', link: '/stories'},
  {title: 'Achievements', link: '/achievements'},
  {title: 'Activities', link: '/activities'},
  {title: "Pushpa's Videos", link: '/pushpa-video'},
  {title: 'NewsLetter', link: '/newsletter'},
  {title: 'Map', link: '/map'},
  {title: 'Wishlist', link: '/wishlist'},
  {title: 'Volunteer', link: '/volunteer'}
]

const DashboardPage = (props ) => {
  const classes = useStyles();
  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <Grid item xs={12} className={classes.root}>
          {LINKS.map((item, index) =>{
            return(
              <Paper key = {index} onClick = {() => props.history.push(item.link) } className={classes.paper}>
                <span className={classes.icons}>
                  {
                    SELECT_ICONS[item.title]
                  }
                </span>
                {item.title}
              </Paper>
              
            );
          })}
        </Grid>
      </div>
    </div>
  )
}

export default DashboardPage;