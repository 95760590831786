import React, {useState} from "react";
import MaterialTable from "material-table";
import { config } from "../services/config";

import Cookies from "universal-cookie";


// COMPONENTS 
import AddTimeline from "./addTimeline/AddTimeline.component";
import EditHistoryTimeline from "./editHistory/EditHistory.component"
// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");


const HistoryTimeline = () => {
  const [historyTimelineEdit, setHistoryTimelineEdit] = useState();  
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div style={{ position: "relative", zIndex: 0 }}>
          <a href="#add">
            <div className="table-addbutton text-rumpel">
              ADD
            </div>
          </a>
          <MaterialTable
            columns={[
              {
                title: "Image",
                field: "image",
                render: rowData => (
                  <img
                    alt="MediaImage"
                    style={{ width: 250, borderRadius: 4 }}
                    src={rowData.image}
                  />
                )
              },
              { title: "Title", field: "title" },
              { title: "Description", field: "description" },
              { title: "Date", field: "date" }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                fetch(config.timeline + `/${query.pageSize}?page=${query.page + 1 }`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                  }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success || result.success === false) {
                      resolve({
                        data: result.data.data,
                        page: query.page,
                        totalCount: result.data.total
                      });
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
            }
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  fetch(config.deleteTimeline + oldData.id, {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                }),
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  setHistoryTimelineEdit(rowData)
                  handleClickOpen()
                  // document.getElementById('add-article').scrollIntoView()
    
                  // props.history.push("/our-work/", {
                  //   editData: rowData
                  // });
                }
              }
            ]}
            title="History Timeline"
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false
            }}
          />
        </div>
        <div id="add" style = {{paddingTop: 150}}>
            {/* ADD HERE  */}
            <AddTimeline />
        </div>
        <EditHistoryTimeline historyTimelineValue={historyTimelineEdit} openStatus={open} onCloseClick={handleClose} />
      </div>
    </div>
  );
};

export default HistoryTimeline;
