import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const getOurWorks = () => {
    return axios.get(config.ourwork)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log(error);
        })
}

export const createProjects = async (data, image) => {
    let formData = new FormData();
    await formData.append('title', data.title)
    await formData.append('description', data.description)
    await formData.append('image', image[0][0])
    return axios.post(config.createProject, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const editProject = async (data,id,image) => {
    let formData = new FormData();
    await formData.append('title', data.title)
    await formData.append('description', data.description)

    if (typeof (image) === "string") {
        await formData.append('image', image)
    } else if (typeof (image) === "object") {
        await formData.append('image', image[0])
    }

    return axios.post(config.editProject + id, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}


export const createProgramme = async (data) => {
    return axios.post(config.createProgramme, {
        project_id: data.id.id,
        name: data.name
    },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}


export const getProgramme = () => {
    return axios.get(config.ourProgramme,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log(error);
        })
}



export const addArticles = async (data,image,thumbnail) => {
    let formData = new FormData();
    let images = image[0]
    for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i])
    }
    await formData.append('title', data.title)
    await formData.append('description', data.description)
    await formData.append('article_date', data.date)
    await formData.append('programme_id', data.program.id)
    await formData.append('thumbnail',thumbnail[0])

    return axios.post(config.addArticles, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const editArticles = async (data,id,image,thumbnail) => {
        let formData = new FormData();
        if(image.length>0){
            let images = image[0]
            for (let i = 0; i < images.length; i++) {
                formData.append('images[]', images[i])
            }
        }
        await formData.append('title', data.title)
        await formData.append('description', data.description)
        await formData.append('article_date', data.date)
        await formData.append('programme_id', data.program.id)
        
        if(typeof(thumbnail)==="string"){
            await formData.append('thumbnail',thumbnail)
        }else if(typeof(thumbnail)==="object"){
            await formData.append('thumbnail',thumbnail[0])
        }
    
        return axios.post(config.editArticles+id, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                console.log('error', error);
            })
    }

export const deleteArticleImage = async (id) => {

    return axios.delete(config.deleteArticleImage + id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}