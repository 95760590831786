import { config } from "../config";

export const login = async ( email, password ) => {
  return fetch(config.login, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: `${email}`.toLowerCase(),
      password: password
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};