import React from "react";
import MaterialTable from "material-table";
import Cookies from "universal-cookie";
import { config } from "../services/config";

const cookies = new Cookies();

const token = cookies.get("browser_ID");

const OrderPage = () => {

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div style={{ position: "relative", zIndex: 0 }}>
          <MaterialTable
            columns={[
              { title: "Name", field: "full_name" },
              { title: "Product", field: "product_name" },
              { title: "quantity", field: "number_of_item" },
              { title: "Email", field: "email" },
              { title: "Date", field: "created_at" },
              { title: "Location", field: "location" },
              { title: "Detected", field: "system_found_location" },
              { title: "Message", field: "message" },
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                fetch(config.orders + `${query.pageSize}?page=${query.page + 1 }`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                  }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success || result.success === false) {
                      resolve({
                        data: result.data.data,
                        page: query.page,
                        totalCount: result.data.total
                      });
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
            }
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  console.log("olddata",oldData)
                  fetch(config.orders + oldData.id, {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                }),
            }}
            title="Products"
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
