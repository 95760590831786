import React, {useState} from "react";
import MaterialTable from "material-table";
import { config } from "../services/config";
import Cookies from "universal-cookie";

// COMPONENTS 
import AddPress from "./addPress/AddPress.component";
import EditPressAndMedia from "./editPressAndMedia/EditPressAndMedia.component"
// COOKIE
const cookies = new Cookies();

const token = cookies.get("browser_ID");





const PressAndMediaPage = () => {
  const [pressMediaEdit, setPressMediaEdit] = useState();  
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div style={{ position: "relative", zIndex: 0 }}>
          <a href="#add">
            <div className="table-addbutton text-rumpel">
              ADD
            </div>
          </a>
          <MaterialTable
            columns={[
              {
                title: "Avatar",
                field: "avatar",
                render: rowData => (
                  <img
                    alt="MediaImage"
                    style={{ height: 36 }}
                    src={rowData.image}
                  />
                )
              },
              { title: "Media Name", field: "media_name" },
              { title: "Description", field: "title" }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                fetch(config.press + `/${query.pageSize}?page=${query.page + 1 }`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                  }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.success || result.success === false) {
                      resolve({
                        data: result.data.data,
                        page: query.page,
                        totalCount: result.data.total
                      });
                    } else {
                      reject(console.log("error"));
                    }
                  });
              })
            }
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  fetch(config.deleteMedia + oldData.id, {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                    .then(response => response.json())
                    .then(result => {
                      if (result.success) {
                        resolve();
                      } else {
                        reject(console.log("error"));
                      }
                    });
                }),
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  setPressMediaEdit(rowData)
                  handleClickOpen()
                  // document.getElementById('add-article').scrollIntoView()
    
                  // props.history.push("/our-work/", {
                  //   editData: rowData
                  // });
                }
              }
            ]}
            title="Press and Media"
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false
            }}
          />
        </div>
        <div id="add" style = {{paddingTop: 150}}>
            <AddPress />
        </div>
        <EditPressAndMedia pressMediaValue={pressMediaEdit} openStatus={open} onCloseClick={handleClose} />
      </div>
    </div>
  );
};

export default PressAndMediaPage;
