import Cookies from "universal-cookie";

// COOKIE
const cookies = new Cookies();

// ALL UTILITIES FUNCTIONS GOES HERE
export const isLoggedIn = () => {
  // CHECK DATABASE FOR LOGGED USER WITH COOKIES / LOCAL STORAGE

  const token = cookies.get("browser_ID");

  if(token) {
    return true;
  }

  return false;
}

// EXPORT OTHER UTILS
export { PublicLink, PrivateLink } from "./Links";