import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const sendNewsletter = async (data,thumbnail) => {
    let formData = new FormData();
    await formData.append('subject', data.subject)
    await formData.append('description', data.description)
    await formData.append('image',thumbnail[0])

    return axios.post(config.newsletter, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}
