import React, { useState } from "react";
import { useForm } from 'react-hook-form'
import { TextField, Slide, CircularProgress } from '@material-ui/core';

import { postProducts } from "../../services/products/Product"

import { DefaultButton, InvertedButton } from "../../common/button/Button.common"


const AddProducts = () => {
    const { register, handleSubmit } = useForm()
    const [loading, setLoading] = useState(false)


    const [image, setImage] = useState([])
    const [thumbnail, setThumbnail] = useState()

    const [showThumbnailError, setShowThumbnailError] = useState(false)
    const [showImageError, setShowImageError] = useState(false)
    const [error, setError] = React.useState(null);

    const onSubmit = async (data) => {
        if (image.length && image[0]?.length && (!(thumbnail === undefined || thumbnail[0] === undefined))) {
            setLoading(true);
            let addProduct = await postProducts(data, image, thumbnail)
            if (addProduct.data.success === true) {
                setLoading(false);
                window.document.location.reload()
            }
            else {
                setError("Adding Product failed please try again");
                setLoading(false);
            }
            setLoading(false);
        }
        if (image.length === 0 || image[0].length === 0) {
            setShowImageError(true)
        }
        if (thumbnail === undefined || thumbnail[0] === undefined) {
            setShowThumbnailError(true)
        }
    }

    const onChangeImage = (event) => {
        setImage([event.target.files]);
        setShowImageError(false)
    }

    const onChangeThumbnail = (event) => {
        setThumbnail([event.target.files[0]]);
        setShowThumbnailError(false)
    }

    return (
        <div className="addarticle-container">
            <div className="addarticle" style={{ background: "white", padding: 20, borderRadius: 8, boxShadow: "0px 3px 10px -5px gray" }}>
            {error && (<Slide
                    in={true}
                    direction="down"
                    timeout={{ enter: 500, exit: 500 }}
                    onEntered={() => setTimeout(() => setError(null), 2000)}
                    style={{
                        zIndex: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40%",
                        left: "30%",
                        margin: "0px auto",
                        top: 140,
                        position: "fixed"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#DC3545",
                            padding: 20,
                            color: "white",
                            borderRadius: 4,
                            boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >{error}</div>
                </Slide>)}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: 10 }}>
                        <div className="text-thin text-bold text-black mb-4">
                            Add Product
                        </div>
                        <TextField name="title" inputRef={register({ required: true })} label="Title" variant="outlined" required />

                        <TextField name="description" multiline
                            rows="4" inputRef={register({ required: true })} label="Description" variant="outlined" required />

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                                <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" multiple name="thumbnail" onChange={(event) => { onChangeImage(event) }} />
                                <DefaultButton title="Add Photos" />
                            </div>

                            {image[0]?.length &&
                                <div className="text-rumpel">
                                    {image[0]?.length} Images selected
                            </div>}
                        </div>
                        {showImageError &&
                            <div style={{ height: 50, width: 200, boxShadow: "0px 0px 10px 0px #c4c2c2", borderRadius: 8, position: "relative" }}>
                                <div style={{ position: "absolute", height: 20, width: 20, backgroundColor: "white", top: -5, left: 20, transform: "rotate(45deg)" }}></div>

                                <div style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <span className="text-red text-bold"> Image is Required </span>
                                </div>

                            </div>}


                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                                <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" name="thumbnail" onChange={(event) => { onChangeThumbnail(event) }} />
                                <DefaultButton title="Add thumbnail" />
                            </div>
                            {thumbnail &&
                                <div className="text-rumpel">
                                    {thumbnail[0]?.name}
                                </div>
                            }
                        </div>
                        {showThumbnailError &&
                            <div style={{ height: 50, width: 200, boxShadow: "0px 0px 10px 0px #c4c2c2", borderRadius: 8, position: "relative" }}>
                                <div style={{ position: "absolute", height: 20, width: 20, backgroundColor: "white", top: -5, left: 20, transform: "rotate(45deg)" }}></div>

                                <div style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <span className="text-red text-bold"> Thumbnail is Required </span>
                                </div>

                            </div>}
                        {loading ? <div style={{ width: "100%", display: "grid", justifyItems: "center", alignItems: "center" }}><CircularProgress /></div> :
                            <InvertedButton type="submit" title="Submit" />
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}


export default AddProducts