import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const addWishlist = async (data,thumbnail) => {
    let formData = new FormData();
    await formData.append('title', data.title)
    await formData.append('price', data.price)
    await formData.append('description', data.description)
    await formData.append('image',thumbnail[0])

    return axios.post(config.addWishlist, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const editWishlist = async (data,id,image) => {
        let formData = new FormData();
        await formData.append('title', data.title)
        await formData.append('price', data.price)
        await formData.append('description', data.description)
        if(typeof(image)==="string"){
            await formData.append('image',image)
        }else if(typeof(image)==="object"){
            await formData.append('image',image[0])
        }
        return axios.post(config.editWishList+id, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                console.log('error', error);
            })
    }

    