import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const addTimeline = async (data,thumbnail) => {
    console.log('data', data)
    let formData = new FormData();
    await formData.append('title', data.title)
    await formData.append('description', data.description)
    await formData.append('historyTime', data.date)
    await formData.append('image',thumbnail[0])

    return axios.post(config.addTimeline, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}

export const editTimeline = async (data,id,image) => {
    let formData = new FormData();
    await formData.append('title', data.title)
    await formData.append('description', data.description)
    await formData.append('historyTime', data.date)
    if(typeof(image)==="string"){
        await formData.append('image',image)
    }else if(typeof(image)==="object"){
        await formData.append('image',image[0])
    }
    return axios.post(config.editTimeline+id, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}