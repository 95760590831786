import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from 'react-hook-form'
import { TextField, Slide, Button, CircularProgress } from '@material-ui/core';
import MaterialTable from "material-table";
import { createProjects } from "../../services/ourWork/ourWork"

import EditProject from "../editProject/EditProject.component"

const ProjectList = ({projectlist}) => {
    const { register, handleSubmit } = useForm()
    const [image, setImage] = useState([])
    const [projectDataEdit, setProjectDataEdit] = useState();  
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false)
    const [error, setError] = React.useState(null);

    const onChangeImage = (event) => {
        setImage([...image, event.target.files]);
        
    }

    const addProject = async (data) => {
        setLoading(true);
        let createproject = await createProjects(data,image)
        if(createproject.data.success===true){
            setLoading(false);
            window.document.location.reload()
        }
        else{
            setError("adding Project failed please try again");
            setLoading(false);
        }
        setLoading(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div className="projectlist-container">
            <div className="projectlist" 
            >                
            {error && (
          <Slide
            in={true}
            direction="down"
            timeout={{ enter: 500, exit: 500 }}
            onEntered={() => setTimeout(() => setError(null), 2000)}
            style={{
              zIndex: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              left:"30%",
              margin:"0px auto",
              top: 140,
              position: "fixed"
            }}
          >
            <div 
            style={ {
              backgroundColor: "#DC3545",
              padding: 20,
              color: "white",
              borderRadius: 4,
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            >{error}</div>
          </Slide>
        )}
                {projectlist&&<MaterialTable
                    columns={[
                        { title: "Title", field: "title" },
                        { title: "Description", field: "description" },
                    ]}
                    data={projectlist}
                    title="Current Projects"
                    options={{
                        actionsColumnIndex: -1,
                        paging:false,
                        emptyRowsWhenPaging: false,
                        search: false
                    }}
                    actions={[
                        {
                          icon: "edit",
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            setProjectDataEdit(rowData)
                            handleClickOpen()
                          }
                        }
                      ]}
                />}


                <div className="projectlist-add"
                style={{ background: "white", padding: 20, borderRadius: 8, boxShadow: "0px 3px 10px -5px gray" }}
                >
                    <div className="text-thin text-bold">
                        Create Project
                    </div>
                    <form onSubmit={handleSubmit(addProject)}>
                        <div className="projectlist-add-text mt-3">
                            <TextField
                                name="title"
                                inputRef={register({ required: true })}
                                variant="outlined"
                                fullWidth
                                placeholder="Add Project"
                                required
                                margin='normal'
                            />

                            <TextField
                                name="description"
                                inputRef={register({ required: true })}
                                margin='normal'
                                variant="outlined"
                                fullWidth
                                multiline
                                rows="3"
                                placeholder="Add Description"
                                required
                            />
                            <input className="mb-4" type="file" name="thumbnail" onChange={(event) => { onChangeImage(event) }} />

                            {loading?<div style={{width:"100%",display:"grid",justifyItems:"center",alignItems:"center"}}><CircularProgress/></div>:
                                <Button
                                className="mt-5"
                                style={{ height: "100%" }}
                                type="submit"
                                disabled={image.length===0?true:false}
                                variant="contained">Add</Button>
                            }
                        </div>
                    </form>
                </div>
                    <EditProject projectDataValue={projectDataEdit} openStatus={open} onCloseClick={handleClose}/>
                      

            </div>
        </div>
    )
}

ProjectList.propTypes = {
    projectlist: PropTypes.array.isRequired,
};
export default ProjectList