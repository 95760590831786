import React,{ useEffect, useState } from "react";
import ProjectList from "./projectList/ProjectList.component";
import ProgramList from "./programList/ProgramList.component";
import AddArticle from "./addArticle/AddArticle.component";
import ArticleList from "./articleList/ArticleList.component";
import { getProgramme, getOurWorks } from "../services/ourWork/ourWork"

const OurWorkPage = (props) => {
  const [program, setProgram] = useState([])
  const [project, setProject] = useState([])
  const [ourProps, setOurProps] = useState()

  useEffect(() => {
    async function fetchData(){
      let ourWorks = await getProgramme();
      if (ourWorks?.data?.success===true){
        setProgram(ourWorks.data.data)
      }
    }
    fetchData();
  },[]);

  useEffect(() => {      
    async function fetchData(){
      let ourWorks = await getOurWorks();
      if (ourWorks?.data?.success===true){
        setProject(ourWorks.data.data)
      }
    }
    fetchData();
  },[]);

  useEffect(() => {
    setOurProps(props.location.state)
  },[props]);

  return (
    <div className="dashboard-container" >
      <div className="dashboard">
        <ArticleList {...props} programlist={program} />
        <div id ="add-article" style={{ paddingTop: 150}}>
          <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 20}}>
            <AddArticle programlist={program} ourProps={ourProps}/>
            <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 20 }}>
              <ProjectList projectlist={project} />
              <ProgramList programlist={program} projectlist={project}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurWorkPage;