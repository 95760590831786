import axios from 'axios'
import { config } from "../config"
import Cookies from "universal-cookie"

const cookies = new Cookies();
const token = cookies.get("browser_ID");


export const addActivities = async (data,image) => {
    let formData = new FormData();
    let images = image[0]
    for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i])
    }
    await formData.append('title', data.title)
    await formData.append('description', data.description)

    return axios.post(config.activities, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
        })
}

export const editActivities = async (data,id,image) => {    
        let formData = new FormData();
        if(image.length>0){
            let images = image[0]
            for (let i = 0; i < images.length; i++) {
                formData.append('images[]', images[i])
            }
        }
        await formData.append('title', data.title)
        await formData.append('description', data.description)
        
        return axios.post(config.editActivities+id, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(function (response) {
                return response
            })
            .catch(function (error) {

            })
    }
    
export const deleteActivitiesImage = async (id) => {

    return axios.delete(config.deleteActivitiesImage + id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log('error', error);
        })
}